import React from 'react'
import Layout from '../components/layout'
import { Grid } from 'semantic-ui-react'

import {
  LayoutSection,
  RatingRow,
  primaryLayoutColor,
  secondaryLayoutColor,
} from '../components/layout-components'

const ratingData = [
  {
    header: 'Betriebssysteme',
    color: primaryLayoutColor,
    ratings: [
      ['Linux (GNU/Debian)', 7],
      ['Windows', 6],
    ],
  },
  {
    header: 'Programmiersprachen',
    color: secondaryLayoutColor,
    ratings: [
      ['Python', 7],
      ['JavaScript', 6],
      ['ANSI C', 6],
      ['C++', 5],
      ['Java', 5],
    ],
  },
  {
    header: 'Frameworks',
    color: primaryLayoutColor,
    ratings: [
      ['Node.js', 7],
      ['D3.js', 6],
      ['Docker', 6],
      ['OpenStack', 5],
    ],
  },
  {
    header: 'Datenbanken',
    color: secondaryLayoutColor,
    ratings: [
      ['MongoDB', 7],
      ['MySQL/SQLite', 6],
    ],
  },
  {
    header: 'Kommunikation',
    color: primaryLayoutColor,
    ratings: [
      ['HTTP', 7],
      ['MQTT', 6],
      ['CoAP', 4],
    ],
  },
  {
    header: 'Tools',
    color: secondaryLayoutColor,
    ratings: [
      ['Shell (Bash/Zsh)', 7],
      ['Git', 7],
      ['LaTeX', 6],
    ],
  },
  {
    header: 'Standards',
    color: primaryLayoutColor,
    ratings: [
      ['oneM2M', 7],
      ['OPC UA', 5],
    ],
  },
]

const SkillsPage = () => (
  <Layout>
    {ratingData.map((section, i) => (
      <LayoutSection key={i} color={section.color} header={<h3>{section.header}</h3>} text>
        <Grid centered>
          {section.ratings.map((rating, i) => (
            <RatingRow key={i} item={rating[0]} rating={rating[1]} />
          ))}
        </Grid>
      </LayoutSection>
    ))}
  </Layout>
)

export default SkillsPage
